import React, { Suspense } from "react"; // lazy
import loadable from "@loadable/component";
import ReactDOM from "react-dom";
import { Route, Switch } from "react-router-dom";

import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import configureStore, { history } from "./configureStore";

import "@/assets/fonts/sl-fonts/style.css";
import "@/index.scss";

const Desktop = loadable(() => import(
	// webpackChunkName: "desktop",
	// webpackPrefetch: true
	"./components/Desktop")
);

const ErrorPage = loadable(() => import(
	// webpackChunkName: "error-page",
	// webpackPrefetch: true
	"./components/ErrorPage")
);

/**
 * for debugging
 */
 const ImportDemo = loadable(() => import(
	// webpackChunkName: "import-demo",
	// webpackPrefetch: true
	"./components/ImportDemo")
);

const MaintenancePage = loadable(() => import(
	// webpackChunkName: "maintenance-page",
	// webpackPrefetch: true
	"./components/MaintenancePage")
);

const MobileGame = loadable(() => import(
	// webpackChunkName: "mobile-game",
	// webpackPrefetch: true
	"./components/MobileGame")
);

const MobileLobby = loadable(() => import(
	// webpackChunkName: "mobile-lobby",
	// webpackPrefetch: true
	"./components/MobileLobby")
);




/**
 * for debugging
 */
const NavigatorTest = loadable(() => import(
	// webpackChunkName: "navigator-test",
	// webpackPrefetch: true
	"./components/NavigatorTest")
);

/**
 * for debugging
 */
 const Readme = loadable(() => import(
	// webpackChunkName: "readme",
	// webpackPrefetch: true
	"./components/Readme")
);

const RoadmapTest = loadable(() => import(
	// webpackChunkName: "roadmap-test",
	// webpackPrefetch: true
	"./components/RoadmapTest")
);

/**
 * for debugging
 */
 const ScreenSizeTest = loadable(() => import(
	// webpackChunkName: "screen-size-test",
	// webpackPrefetch: true
	"./components/ScreenSizeTest")
);

/**
 * for debugging
 */
 const SwipeUpDemo = loadable(() => import(
	// webpackChunkName: "swipe-up-demo",
	// webpackPrefetch: true
	"./components/SwipeUpDemo")
);

const store = configureStore(); //createRootReducer

const renderLoader = <p>Loading...</p>;

class App extends React.Component {
	render() {
		// console.log("<App />", this.props);

		return (
			<Switch>
				<Route exact path="/" component={Desktop} />
				<Route path="/maintenance/:version?" component={MaintenancePage} />
				<Route path="/errors/:errorname" component={ErrorPage} />

				{ /* Mobile-only routes */}
				<Route path="/m/:game" component={MobileLobby} />
				<Route path="/m" component={MobileLobby} />
				<Route path="/play/:game" component={MobileGame} />

				{ /* Development routes */}
				<Route path="/navigator" component={NavigatorTest} />
				<Route path="/screensize" component={ScreenSizeTest} />
				<Route path="/importdemo" component={ImportDemo} />
				<Route path="/swipeupdemo" component={SwipeUpDemo} />
				<Route path="/readme/:page" component={Readme} />
				<Route path="/readme" component={Readme} />
				<Route path="/roadmap-test" component={RoadmapTest} />

				<Route component={ErrorPage} {...{ errorType: "404" }} />
			</Switch>
		);
	}
}

ReactDOM.render((
	<React.StrictMode>
		<Suspense fallback={renderLoader}>
			<Provider store={store}>
				<ConnectedRouter history={history}>
					<App />
				</ConnectedRouter>
			</Provider>
		</Suspense>
	</React.StrictMode>
), document.getElementById("root"));
